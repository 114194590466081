<template>
  <div class="login mt-6 pt-6">
    <div class="container">
      <div class="columns is-justify-content-center">
        <div class="column is-two-fifths-tablet">
          <header class="has-text-centered">
            <img
                class="login-logo mb-4"
                src="/img/assets/logo.png"
                alt="Sgara Logo">

            <h3 class="is-size-5 has-text-weight-bold">Verifying your email</h3>
            <p v-if="loading">Please wait</p>
          </header>

          <b-notification
              :active.sync="showError"
              :closable="false"
              class="my-4 has-text-centered"
              type="is-danger is-light"
              aria-close-label="Close notification"
              role="alert">
            {{ errorMessage }}
          </b-notification>

          <b-notification
              :active.sync="showSuccess"
              :closable="false"
              class="my-4 has-text-centered"
              type="is-success is-light"
              aria-close-label="Close notification"
              role="alert">
            {{ successMessage }}
          </b-notification>

          <div class="has-text-centered mt-4">
            <b-button tag="router-link" to="/" type="is-primary" :loading="loading">Back to Login Page</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Login",
  data: () => {
    return {
      showError: false,
      errorMessage: '',
      showSuccess: false,
      successMessage: '',
      loading: true,
    }
  },
  computed: {
    signature() {
      return this.$route.query.signature
    },
  },
  created() {
    this.verifyEmail()
  },
  methods: {
    ...mapActions('user', [
      'emailVerification'
    ]),

    verifyEmail() {
      this.emailVerification(this.signature).then(res => {
        if (res.error) {
          this.showError = true
          this.errorMessage = res.data.message
        } else {
          this.showSuccess = true
          this.successMessage = res.data.message
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
